import styles from './HeaderDropdown.module.scss';

import classNames from 'classnames';

const HeaderDropdown = (props) => {
  const { isVisible, children } = props;

  return (
    <div
      className={classNames({
        [styles.HeaderDropdown]: true,
        [styles.HeaderDropdownActive]: isVisible,
      })}
    >
      <div className={styles.HeaderDropdownItemsWrapper}>{children}</div>
    </div>
  );
};

export default HeaderDropdown;
