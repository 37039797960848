import React, { useEffect, useState, } from 'react';
import getUser from '../utils/getUser';
import _ from 'lodash';
import UnknownProgressLoader from '../components/UnknownProgressLoader';
import { useRouter, } from 'next/router';
import { Circles } from "react-loader-spinner";

interface RoleGuardProps {
    children: any;
}

export default function LoginGuard( props: RoleGuardProps ) {
	const {
		children,
	} = props;

	const router = useRouter();

	const [
		isLoading, setIsLoading,
	] = useState( true );

	useEffect( ( ) => {
		getUser().then(
			( user ) => {
				if ( _.isEmpty( user ) ) {
					router.push( 'redirect' );
				}
				setIsLoading( false );
			}
		);
	}, [
	] );

	if ( isLoading ) {
		return   <div className="Loader">
		<Circles
		  height="80"
		  width="80"
		  color="red"
		  ariaLabel="circles-loading"
		  wrapperStyle={{}}
		  wrapperClass=""
		  visible={true}
		/>
	  </div>;
	}

	return children;
}
