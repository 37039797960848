export type BaseRequestParams = {
    url: string;
    body?: Record<string, unknown>;
    method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    headers?: Record<string, string>;
    signal?: AbortSignal;
    qfTraceID?: string;
    [key: string]: any;
}

export default function request( params: BaseRequestParams, ) {
	const {
		url,
		body,
		headers = {},
		...options
	} = params;

	if ( body && typeof body === 'object' ) {
		options.body = JSON.stringify( body, );
	}

	return globalThis.fetch(
		url,
		{
			...options,
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
		},
	);
}
