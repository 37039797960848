import request, { BaseRequestParams, } from '../request';

function _getURL( url: string, ) {
	return `/api/${url}`;
}

const INIT_OPTIONS = {
	mode: 'same-origin',
	credentials: 'same-origin',
};

export default function clientRequest( params: BaseRequestParams | string, ): Promise<Response> {
	if ( typeof params === 'string' ) {
		return request( {
			...INIT_OPTIONS,
			url: _getURL( params, ),
		}, );
	}

	const {
		url,
		...options
	} = params;
	return request( {
		...options,
		...INIT_OPTIONS,
		url: _getURL( url, ),
	}, ).then( ( response, ) => {
		if ( !response.ok ) {
			throw response;
		}
		return response;
	}, );
}
