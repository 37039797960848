// @ts-nocheck
import React, { memo, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { Footer, Header, SubHeader } from "components";
import HeaderAdmin from "admin/components/Templates/Header";
import { handlerScrollUp, posTop } from "utils";

import Router from 'next/router';

import "../styles/styles.scss";
import "tailwindcss/tailwind.css";

import LoginGuard from "admin/src/containers/LoginGuard";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import Menu from "admin/src/components/Menu/index";

import Cookies from 'js-cookie';
import CookieConsent from "react-cookie-consent";

const MyApp = ({ Component, pageProps }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (pageProps?.isAdminPage) {
      return;
    } else {
      if (!Cookies.get('CookieConsent')) {
        setIsVisible(true);
      }else {
        setIsVisible(false);
      };
    };
  }, [Cookies.get('CookieConsent')])

  const clearAllCookies = () => {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach(cookie => {
      Cookies.remove(cookie);
    });
    Cookies.set('CookieConsent', 'false');
    Router.reload();
  }

  const queryClient = new QueryClient();
  const pageView = (event) => {
    window &&
      window.dataLayer &&
      window.dataLayer.push({
        event: event,
      });
  };

  queryClient.setDefaultOptions({
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  });

  return (
    <>
      {!pageProps?.isAdminPage && (
        <script src="//code.jivo.ru/widget/oEnwWX7boW" async></script>
      )}

      <QueryClientProvider client={queryClient}>
        {pageProps?.isAdminPage && (
          <div className={"w-full h-full"}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <LoginGuard>
                <HeaderAdmin />
                {/* <Menu> */}
                <Component {...pageProps} />
                {/* </Menu> */}
              </LoginGuard>
            </MuiPickersUtilsProvider>
          </div>
        )}

        {isVisible && (
          <CookieConsent
            location="bottom"
            buttonText="Принять"
            declineButtonText="Отклонить"
            enableDeclineButton
            style={{
              background: "#2A1544",
              boxShadow: "0px 2px 10px 0px rgba(10, 19, 70, 0.30)",
              color: "#fff",
              fontSize: "16px",
              display: "flex",
              padding: "15px 20px",
              alignItems: "center",
              zIndex: 2147483647,
            }}
            declineButtonStyle={{
              color: "#fff",
              fontSize: "16px",
              borderRadius: "6px",
              background: "transparent",
              border: "1px solid #DE1F1F",
              margin: "5px",
            }}
            buttonStyle={{
              color: "#fff",
              fontSize: "16px",
              borderRadius: "6px",
              background: "#DE1F1F",
              margin: "5px",
            }}
            expires={150}
          >
            Мы используем cookies. Нажимая на кнопку "Принять", вы соглашаетесь
            с нашей{" "}
            <a
              style={{
                color: "#DE1F1F",
                marginLeft: "3px",
                textDecoration: "underline",
              }}
              href="/privacy-policy"
            >
              политикой обработки персональных данных
            </a>
            .
          </CookieConsent>
        )}

        {!pageProps?.isAdminPage && (
          <section className={`app_main`}>
            <div className={"app_main-wrapper"}>
              <Header />
              <SubHeader />
              <Component {...pageProps} pageView={pageView} />
              <Footer />
              {posTop() > 400 && (
                <div onClick={handlerScrollUp} className="go_top">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6665 30L26.6665 20L16.6665 10"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          </section>
        )}
      </QueryClientProvider>
    </>
  );
};

export default memo(MyApp);
