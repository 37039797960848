import request from '../request/client';

export default async function getUser( ) {
	const userResponse = await request( {
		url: 'user',
		method: 'GET',
	} );

	return await userResponse.json();
}
