import styles from './Header.module.scss';

import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import HeaderDropdown from 'admin/components/Molecules/HeaderDropdown';
import classNames from 'classnames';

import getUser from 'admin/src/utils/getUser';

const Header = (props) => {
  const router = useRouter();

  const [isPopupInfoVisible, setIsPopupInfoVisible] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  const [scrollY, setScrollY] = useState(0);
  const [authorities, setAuthorities] = useState<any>([]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getUser().then(
      (user) => {
        const {
          authorities = [],
        } = user;
        setAuthorities(authorities);
      }
    );
  }, []);

  const handleMobileItemClick = (path: any) => {
    router.push(path);
    setIsMobileMenuActive(false);
  };

  return (
    <>
      <div className={styles.MainInfoBlockHeader}>
        <a className={styles.HeaderLogo} href='/'>
          OLDMOUSE ADMIN
        </a>

        <div className={styles.MainInfoBlockHeaderLinksWrapper}>
          {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&
            <div
              className={styles.MainInfoBlockHeaderLink}
              onMouseEnter={() => setIsPopupInfoVisible(true)}
              onMouseLeave={() => setIsPopupInfoVisible(false)}
            >
              Админ
              {/* <ArrowDownIcon className={styles.MainInfoBlockHeaderIcon} /> */}
              <HeaderDropdown isVisible={isPopupInfoVisible}>
                <div className={styles.HeaderDropdownItem} onClick={() => router.push('/admin')}>Администраторы</div>
                <div className={styles.HeaderDropdownItem} onClick={() => router.push('/admin-quests')}>Квесты</div>
                <div className={styles.HeaderDropdownItem} onClick={() => router.push('/admin-news')}>Новости</div>
                <div className={styles.HeaderDropdownItem} onClick={() => router.push('/admin-sheldule')}>Шаблоны квестов</div>
              </HeaderDropdown>
            </div>
          }
          <div className={classNames({
            [styles.MainInfoBlockHeaderLink]: true,
            [styles.MainInfoBlockHeaderLinkActive]: router.pathname === '/',
          })} onClick={() => router.push('/')}>
            Бронирования
          </div>

          {authorities?.some(item => item.authority === 'ROLE_MANAGER') &&
            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname.includes('admin-sertificate'),
            })} onClick={() => router.push('/admin-sertificate')}>
              Сертификаты
            </div>
          }
          {authorities?.some(item => item.authority === 'ROLE_MANAGER') &&
            <div className={styles.MainInfoBlockHeaderLink} onClick={() => router.push('/admin-booking-archive')}>
              Архив броней
            </div>
          }
          {authorities?.some(item => item.authority === 'ROLE_MANAGER') &&
            <div className={styles.MainInfoBlockHeaderLink} onClick={() => router.push('/sertifikaty')}>
              Клиенты
            </div>
          }
        </div>

        {authorities?.some(item => item.authority === 'ROLE_MANAGER' || item.authority === 'ROLE_ADMIN') &&
          <div className={styles.MobileMenu} onClick={() => setIsMobileMenuActive(prevState => !prevState)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12H15M3 6H21M3 18H21" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        }

        <div className={classNames({
          [styles.MobileMenuDrop]: true,
          [styles.MobileMenuDropActive]: isMobileMenuActive,
        })}>
          <div className={classNames({
            [styles.MainInfoBlockHeaderLink]: true,
            [styles.MainInfoBlockHeaderLinkActive]: router.pathname === '/',
          })} onClick={() => handleMobileItemClick('/')}>
            Бронирования
          </div>

          {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&
            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname === '/admin',
            })} onClick={() => handleMobileItemClick('/admin')}>
              Администраторы
            </div>
          }
          {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&

            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname.includes('admin-quests'),
            })} onClick={() => handleMobileItemClick('/admin-quests')}>
              Квесты
            </div>
          }

          {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&
            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname.includes('admin-news'),
            })} onClick={() => handleMobileItemClick('/admin-news')}>
              Новости
            </div>
          }
          {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&

            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname.includes('admin-sheldule'),
            })} onClick={() => handleMobileItemClick('/admin-sheldule')}>
              Шаблоны квестов
            </div>
          }
          {authorities?.some(item => item.authority === 'ROLE_MANAGER') &&
            <div className={classNames({
              [styles.MainInfoBlockHeaderLink]: true,
              [styles.MainInfoBlockHeaderLinkActive]: router.pathname.includes('admin-sertificate'),
            })} onClick={() => handleMobileItemClick('/admin-sertificate')}>
              Сертификаты
            </div>
          }

          {/* <div className={styles.MainInfoBlockHeaderLink} onClick={() => handleMobileItemClick('/manager?tab=bookingArchive')}>
            Архив броней
          </div>

          <div className={styles.MainInfoBlockHeaderLink} onClick={() => handleMobileItemClick('/sertifikaty')}>
            Клиенты
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Header;
